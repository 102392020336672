// extracted by mini-css-extract-plugin
export const active = "ActiveLearning-module--active--QT2BB";
export const content = "ActiveLearning-module--content--oFxpA";
export const title = "ActiveLearning-module--title--doHQ4";
export const description = "ActiveLearning-module--description--1YIcH";
export const blocks = "ActiveLearning-module--blocks--2WFaU";
export const block = "ActiveLearning-module--block--3DoHG";
export const block__title = "ActiveLearning-module--block__title--2Kn4Z";
export const block__text = "ActiveLearning-module--block__text--2g71W";
export const block__postscript = "ActiveLearning-module--block__postscript--3eBy_";
export const block__image = "ActiveLearning-module--block__image--1o5Zn";
export const block__image__salute = "ActiveLearning-module--block__image__salute--W8fe4";
export const video = "ActiveLearning-module--video--1Xe6R";
export const hide = "ActiveLearning-module--hide--174lH";