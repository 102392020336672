// extracted by mini-css-extract-plugin
export const quickly = "Quickly-module--quickly--frF1h";
export const content = "Quickly-module--content--QFKpQ";
export const content__info = "Quickly-module--content__info--2JxAX";
export const content__header = "Quickly-module--content__header--3tGbl";
export const title = "Quickly-module--title--1lYZL";
export const mark = "Quickly-module--mark--3Z_zw";
export const description = "Quickly-module--description--2828L";
export const sup__button = "Quickly-module--sup__button--Z_xt0";
export const start__button = "Quickly-module--start__button--2NzQw";
export const video__wrapper = "Quickly-module--video__wrapper--1zUij";
export const video = "Quickly-module--video--3jKqx";
export const movie = "Quickly-module--movie--cyx_p";
export const video__play = "Quickly-module--video__play--1AIWe";
export const open__quote = "Quickly-module--open__quote--2HjcR";
export const close__quote = "Quickly-module--close__quote--RV_L8";